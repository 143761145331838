.information {
  color: #808080;
}

.uppercase {
  text-transform: uppercase;
}

a {
  color: #6238f9;
}

.overlay-button-minus {
  right: 0;
  align-self: flex-end;

  span {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      opacity: 0.7;
      background-color: currentColor;
    }

    &:before {
      transform: translateY(-50%);
    }

    &:after {
      transform: translateY(-50%);
    }
  }
}

.overlay-button-plus {
  right: 0;
  align-self: flex-end;

  span {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      opacity: 0.7;
      background-color: currentColor;
    }

    &:before {
      transform: translateY(-50%);
    }

    &:after {
      transform: translateY(-50%) rotate(-90deg);
    }
  }
}

.overlay-button-close {
  right: 0;
  align-self: flex-end;

  span {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      opacity: 0.7;
      background-color: currentColor;
    }

    &:before {
      transform: translateY(-50%) rotate(45deg);
    }

    &:after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}

.overlay-button-back {
  left: 0;
  align-self: flex-start;

  span {
    &:before,
    &:after {
      top: 50%;
    }

    &:before {
      height: 50%;
      width: 50%;
      left: 10%;
      border-color: currentcolor currentcolor transparent transparent;
      border-style: solid;
      border-width: 1px;
      -webkit-transform: translateY(-50%) rotate(-135deg);
      transform: translateY(-50%) rotate(-135deg);
    }

    &:after {
      width: 100%;
      height: 1px;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background-color: currentColor;
    }
  }
}

.overlay-button {
  position: relative;
  border: 1px solid currentColor;
  border-radius: 100%;
  box-sizing: border-box;
  z-index: 1;
  flex: none;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 45%;
    height: 45%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
    }
  }
}
