@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $breakpoint-vw, $max-vw, $min-font-size, $mid-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);
  $u5: unit($breakpoint-vw);
  $u6: unit($mid-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 and $u1 == $u5 and $u1 == $u6 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($mid-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($breakpoint-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $breakpoint-vw) {
        font-size: calc(
          #{$mid-font-size} + #{strip-unit($max-font-size - $mid-font-size)} *
            ((100vw - #{$breakpoint-vw}) / #{strip-unit($max-vw - $breakpoint-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin font-size-variant($size) {
  $mid-factor: 1.125;
  $max-factor: 2.5;
  $min-font-size: $size;
  $mid-font-size: $size * $mid-factor;
  $max-font-size: $size * $mid-factor * $max-factor;

  @include fluid-type(1366px, 1920px, 3840px, $min-font-size, $mid-font-size, $max-font-size);

  @media only screen and (max-width: 64em) and (orientation: landscape) {
    @include fluid-type(320px, 414px, 1024px, 20px, 24px, 30px);
  }

  @media only screen and (max-width: 48em) and (orientation: portrait) {
    @include fluid-type(320px, 414px, 768px, 22px, 26px, 36px);
  }
}

$text-color-overlay: #fff;
$overlay-primary-color-fallback: #313234;
$overlay-secondary-color-fallback: #4a4c4e;

.MuiDialog-container .dialog-container {
  width: 87%;
  height: 90%;
  max-width: unset;
  max-height: unset;
  border-radius: 6px;
  background: transparent;
}

.overlay-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2.6vh 1.46vw;
  overflow: hidden;
  color: $text-color-overlay;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  background: currentColor;

  .overlay-button {
    width: calc(1.87vw + 5vh);
    height: calc(1.87vw + 5vh);
    min-width: 40px;
    min-height: 40px;
    cursor: pointer;
  }

  .main .text-fade {
    color: $overlay-primary-color-fallback;
    position: absolute;
    bottom: 0;
    height: 15%;
    width: 100%;
    background: linear-gradient(to bottom, rgba($text-color-overlay, 0) 0%, currentColor 100%);
    pointer-events: none;
  }

  .title {
    margin: calc(0.7vw + 1.875vh) 0;
    padding-left: 3%;
    width: 50%;
  }

  .image-holder {
    display: flex;
    padding: 3%;
    padding-right: 0;
    align-items: center;
    height: 100%;
    width: 50%;
    order: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .main {
    position: relative;
    width: 50%;
    box-sizing: border-box;
    flex: 1;
    padding: 0 1% 0 3%;
    min-height: 0;

    .text-wrapper {
      position: relative;
      height: 85%;

      .text-content {
        height: 100%;
        padding-right: 5%;
        overflow: auto;
        overflow-x: hidden;
        scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          -webkit-border-radius: 3px;
          border-radius: 3px;
          background: rgba(255, 255, 255, 0.3);
        }

        a {
          color: inherit;
          text-decoration: underline;
        }

        table {
          border-collapse: collapse;
          display: block;
          overflow-x: auto;
          th,
          td {
            border: 1px solid currentColor;
            overflow-wrap: break-word;
            padding: 0.7em;
          }
        }
      }
    }

    .cookies-btn {
      display: none;
      position: relative;
      z-index: 2;
      text-transform: uppercase;
      margin: 8px 0;
      cursor: pointer;

      span {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;

        svg {
          width: 1.25vw;
          min-width: 18px;
          height: auto;

          g {
            fill: currentColor;
          }
        }
      }
    }
  }

  &.no-image {
    .main,
    .title {
      width: 100%;
    }
  }

  &.privacy-policy .main .cookies-btn {
    display: inline-block;
  }
}

.overlay-container {
  .title {
    @include font-size-variant(36px);
  }

  &.overlay-headline-font-size-64px {
    .title {
      @include font-size-variant(64px);
    }
  }
  &.overlay-headline-font-size-50px {
    .title {
      @include font-size-variant(50px);
    }
  }
  &.overlay-headline-font-size-44px {
    .title {
      @include font-size-variant(44px);
    }
  }
  &.overlay-headline-font-size-38px {
    .title {
      @include font-size-variant(38px);
    }
  }
  &.overlay-headline-font-size-36px {
    .title {
      @include font-size-variant(36px);
    }
  }
}

@media only screen and (max-width: 64em) and (orientation: portrait) {
  .overlay-container {
    padding: calc(1.17vw + 1.04vh);

    .title {
      width: 100%;
      margin: calc(0.76vw + 0.54vh) 0;
    }

    .image-holder {
      height: auto;
      width: 100%;
      padding: 0;
      order: 0;
      max-height: 30%;
    }

    .main {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 64em) and (orientation: landscape) {
  .MuiDialog-container .dialog-container {
    width: 100%;
    height: 100%;
    border-radius: unset;
    margin: 0;
  }
}

/* tablet - 768px */
@media only screen and (max-width: 48em) and (orientation: portrait) {
  .MuiDialog-container .dialog-container {
    width: 100%;
    height: 100%;
    border-radius: unset;
    margin: 0;

    .title {
      @include fluid-type(320px, 414px, 768px, 22px, 24px, 38px);
    }
  }
}

/* phone - 416px */
@media only screen and (max-width: 26em) and (orientation: portrait) {
  .overlay-container {
    .main {
      .text-wrapper {
        .text-content {
          table {
            th,
            td {
              padding: 0.5em;
            }
          }
        }
      }
    }
  }
}
