.MuiAutocomplete-option {
  white-space: pre;
  font-size: 12px;
  font-weight: 500;
}

.MuiAutocomplete-paper.MuiPaper-elevation1 {
  box-shadow: 1px 4px 10px 4px rgba(0, 0, 0, 0.05);
}

.MuiAutocomplete-paper .MuiAutocomplete-listbox {
  overflow-y: auto;
  overflow-x: hidden;
}

.MuiAutocomplete-paper .MuiAutocomplete-listbox.tags-popup {
  max-height: 140px;
  overscroll-behavior: contain;
}

.MuiAutocomplete-popperDisablePortal {
  top: 48px;
}
